/* RESPONSIBLE TEAM: team-data-interop */
import ApplicationAdapter from 'embercom/adapters/application';

function getSingleRecordUrl(code) {
  return `ember/appstore/app_packages/${code}/invite_form_state`;
}

export default ApplicationAdapter.extend({
  urlForFindRecord(appPackageCode) {
    return getSingleRecordUrl(appPackageCode);
  },

  urlForUpdateRecord(appPackageCode) {
    return getSingleRecordUrl(appPackageCode);
  },
});
