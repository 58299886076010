/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import ApplicationAdapter from './application';

export default class extends ApplicationAdapter {
  // no need to type this as it is used internally by Ember Data
  findAll(): any {
    let url = `/ember/usage_reminders/all`;
    return this.ajax(url, 'GET', {});
  }
}
