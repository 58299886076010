/* RESPONSIBLE TEAM: team-proactive-support */
import ApplicationAdapter from '../application';

export default ApplicationAdapter.extend({
  findRecord(store, type, id, snapshot) {
    if (snapshot.adapterOptions) {
      let stats = snapshot.adapterOptions.stats;
      if (stats) {
        let url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
        let query = this.buildQuery(snapshot);
        query.stats = stats;
        query.include_unique_stat_count = true;
        return this.ajax(url, 'GET', { data: query });
      }
    }
    return this._super(...arguments);
  },

  shouldReloadRecord(store, snapshot) {
    if (snapshot.record.rulesetLinks.firstObject?.shouldReloadRecord) {
      return true;
    }

    return this._super(...arguments);
  },
});
