/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import ApplicationAdapter from 'embercom/adapters/application';

export default ApplicationAdapter.extend({
  updateRecord(store, type, snapshot) {
    return this._super(...arguments).then((guideResponseJSON) => {
      store.push(store.normalize('onboarding/home/guide', guideResponseJSON));
    });
  },
});
