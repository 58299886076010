/* RESPONSIBLE TEAM: team-frontend-tech */
import { underscore } from '@ember/string';
import { pluralize } from 'ember-inflector';
import EmbercomAdapter from 'embercom/lib/adapter/embercom-adapter';
import ajax from 'embercom/lib/ajax';
import getAppId from 'embercom/lib/get-app-id';

export default EmbercomAdapter.extend({
  pathForType(type) {
    switch (type) {
      case 'admin-notification-settings':
        return 'admins/notification_settings';
      case 'admin-teammate-app-settings':
        return 'admins/teammate_app_settings';
      case 'ai-agent/settings':
        return 'ai_agent/settings';
      case 'operator/custom-bot-summary':
        return 'operator_custom_bot_summaries';
      case 'operator/custom-bot-reportable-bot':
        return 'operator_custom_bot_reportable_bots';
      case 'operator/custom-bot':
        return 'operator_custom_bots';
      case 'operator/custom-bot-template':
        return 'operator_custom_bot_templates';
      case 'operator-settings/contact-collection':
        return 'operator_settings/contact_collection';
      case 'operator-settings/article-auto-reaction':
        return 'operator_settings/article_auto_reaction';
      case 'operator/custom-bots-settings':
        return 'operator_custom_bots_settings';
      case 'operator/fin-ingestion-states':
        return 'operator_fin_ingestion_states';
      case 'operator/custom-bot-snapshot':
        return 'operator_custom_bot_snapshots';
      case 'appstore/app-package/google-calendar-meetings/app-settings':
        return 'calendar_settings/google_calendar_meetings';
      case 'appstore/app-package/outlook-calendar-meetings/app-settings':
        return 'calendar_settings/outlook_calendar_meetings';
      case 'carousels/carousel':
        return 'carousels';
      case 'articles/article-content-for-editing':
        return 'article_contents';
      case 'articles/article-content':
        return 'article_contents';
      case 'content-service/content-wrapper':
        return 'content_service/contents/search';
      default:
        return underscore(pluralize(type));
    }
  },

  ajax(url, type, options = {}) {
    if (!options.data) {
      options = Object.assign(options, { data: {} });
    }
    if (this.isEmbercom && !options.data.app_id) {
      options.data.app_id = getAppId(this);
    }
    options = this.ajaxOptions(url, type, options);
    return ajax(options);
  },
});
