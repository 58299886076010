/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import RESTAdapter from '@ember-data/adapter/rest';
import ajax from 'embercom/lib/ajax';

export default RESTAdapter.extend({
  findRecord(store, type, id, snapshot) {
    if (snapshot.adapterOptions) {
      let duration = snapshot.adapterOptions.duration;
      if (duration) {
        let url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
        let query = this.buildQuery(snapshot);
        query.duration = duration;
        return ajax({
          type: 'GET',
          url,
          data: query,
        });
      }
    } else {
      this._super(...arguments);
    }
  },
  pathForType() {
    return 'meeting_scheduler/calendar';
  },
});
