/* RESPONSIBLE TEAM: team-tickets-1 */
import ApplicationAdapter from 'embercom/adapters/application';

export default class TeamSettingAdapter extends ApplicationAdapter {
  urlForCreateRecord(_modelName, snapshot) {
    if (snapshot.adapterOptions?.onlyUpdateDistributionMethod) {
      return `${super.urlForCreateRecord(...arguments)}/create_with_distribution_method`;
    }
    return super.urlForCreateRecord(...arguments);
  }
  urlForUpdateRecord(_id, modelName, snapshot) {
    if (snapshot.adapterOptions?.onlyUpdateDistributionMethod) {
      return `${this.buildURL(modelName)}/update_distribution_method`;
    } else if (snapshot.adapterOptions?.onlyUpdateVolumeControl) {
      return `${this.buildURL(modelName)}/update_volume_control`;
    }
    return super.urlForUpdateRecord(...arguments);
  }
  pathForType() {
    return 'inbox/workload_management/team_settings';
  }
}
