/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import ApplicationAdapter from './application';

export default class ArticleContentForEditingAdapter extends ApplicationAdapter {
  buildURL(modelName, id, snapshot, requestType, query) {
    return query && (query.phrase || query.url_partials?.length > 0)
      ? '/ember/articles/search'
      : super.buildURL(modelName, id, snapshot, requestType, query);
  }
}
