/* RESPONSIBLE TEAM: team-workflows */
import ApplicationAdapter from './application';
import { assign } from '@ember/polyfills';
import Permission from 'embercom/models/permission';

export default ApplicationAdapter.extend({
  deleteRecord(_store, type, snapshot) {
    let {
      id,
      adapterOptions: { reassignments, skipValidation },
    } = snapshot;
    let url = this.buildURL(type.modelName, id, snapshot, 'deleteRecord');

    if (!reassignments && !skipValidation) {
      throw new Error('You need to include reassignment options for this request');
    }

    if (skipValidation) {
      return this.ajax(url, 'DELETE');
    }

    let options = { data: Permission.transformReassignments(reassignments) };
    return this.ajax(url, 'DELETE', options);
  },
  updateRecord(store, type, snapshot) {
    let data = {};
    let serializer = store.serializerFor(type.modelName);
    serializer.serializeIntoHash(data, type, snapshot);
    let id = snapshot.id;
    let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

    // this is the only bit different from the default behavior
    let reassignments = snapshot?.adapterOptions?.reassignments;
    if (reassignments) {
      assign(data, this._convertReassignmentsToPayload(reassignments));
    }
    return this.ajax(url, 'PUT', { data });
  },
});
