/* RESPONSIBLE TEAM: team-tickets-1 */
import DefaultJSONAPIAdapter from '@ember-data/adapter/json-api';
import getAppId from 'embercom/lib/get-app-id';

export default class JSONAPIAdapter extends DefaultJSONAPIAdapter {
  namespace = 'ember';

  buildQuery(snapshot) {
    let query = super.buildQuery(snapshot);
    query.app_id = getAppId(this);
    return query;
  }
}
