/* RESPONSIBLE TEAM: team-help-desk-experience */
import ApplicationAdapter from '../application';

export default class ConversationAttributeValue extends ApplicationAdapter {
  updateRecord() {
    return this.updateAttribute(...arguments);
  }

  createRecord() {
    return this.updateAttribute(...arguments);
  }

  updateAttribute(store, type, snapshot) {
    let conversationId = snapshot.record.conversationId;
    let attributeId = snapshot.record.attributeId;
    let serialized = this.serialize(snapshot);

    let data = {
      value: serialized.value,
    };

    return this.ajax(
      `/ember/conversations/${conversationId}/conversation_attributes/${attributeId}`,
      'PUT',
      {
        data,
      },
    );
  }
}
