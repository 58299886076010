/* RESPONSIBLE TEAM: team-customer-data-platform */
import EmbercomAdapter from 'embercom/lib/adapter/embercom-adapter';

export default class ImportConfiguration extends EmbercomAdapter {
  urlForQueryRecord() {
    return '/ember/migrate_from_zendesk_integrations/import_configuration';
  }

  urlForUpdateRecord() {
    return '/ember/migrate_from_zendesk_integrations/import_configuration';
  }
}
