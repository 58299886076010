/* RESPONSIBLE TEAM: team-help-desk-experience */
import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
  query() {
    throw new Error(
      'The conversation model does not support querying through the standard Ember Data interface yet. ' +
        'Please make the request for conversations manually and push your response into the store via `pushPayload`.',
    );
  },

  updateRecord() {
    throw new Error(
      'The conversation model does not support the saving operation. Please mutate the conversation state via converastion parts.',
    );
  },
});
