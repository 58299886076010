/* RESPONSIBLE TEAM: team-help-desk-experience */
import ApplicationAdapter from './application';

/*
 * We currently send every teammate ID in the query, which when it's a GET can result in a 413 error (or dropped connection) for larger teams.
 * This changes the query to use a POST instead, so the IDs are sent in the body vs the query string.
 */
export default ApplicationAdapter.extend({
  query(store, type, query) {
    let url = this.buildURL(type.modelName, null, null, 'query', query);
    return this.ajax(url, 'POST', { data: query });
  },
});
