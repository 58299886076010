/* RESPONSIBLE TEAM: team-proactive-support */
import ApplicationAdapter from './application';
import EmailTemplate from 'embercom/models/email-template';

export default ApplicationAdapter.extend({
  shouldReloadRecord(store, snapshot) {
    return !EmailTemplate.isIntercomBuiltTemplate(snapshot.id);
  },
  shouldBackgroundReloadRecord(store, snapshot) {
    return !EmailTemplate.isIntercomBuiltTemplate(snapshot.id);
  },
});
