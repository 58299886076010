/* RESPONSIBLE TEAM: team-reporting */
import ApplicationAdapter from 'embercom/adapters/application';

export default class ChartAdapter extends ApplicationAdapter {
  urlForCreateRecord(_modelName, snapshot) {
    if (snapshot.adapterOptions?.isTemplatedChart) {
      return `${super.urlForCreateRecord(...arguments)}/create_from_chart_template`;
    }
    return super.urlForCreateRecord(...arguments);
  }
  pathForType() {
    return 'reporting/custom_charts';
  }
}
