/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
  router: service(),

  ajax(url, type, options = {}) {
    if (!options.data) {
      options = Object.assign(options, { data: {} });
    }
    if (this.isEmbercom && !options.data.ra_source) {
      options.data.ra_source = this.router.currentRouteName;
    }
    return this._super(...arguments);
  },
});
