/* RESPONSIBLE TEAM: team-help-desk-experience */
import ApplicationAdapter from '../application';
import { put } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';

export default class Descriptor extends ApplicationAdapter {
  @service appService;

  updateRecord() {
    return this.updateAttribute(...arguments);
  }

  updateAttribute(store, type, snapshot) {
    let changedAttributes = snapshot.changedAttributes();
    let descriptorId = snapshot.id;

    if (changedAttributes.order && Object.keys(changedAttributes).length === 1) {
      let newOrderIdx = changedAttributes.order[1];
      return put(`/ember/conversation_attributes/descriptors/${descriptorId}/reorder`, {
        order: newOrderIdx,
        app_id: this.appService.app.id,
        ticket_type_id: snapshot.record.ticketTypeId,
      });
    }

    let data = this.serialize(snapshot);

    return put(`/ember/conversation_attributes/descriptors/${descriptorId}`, {
      ...data,
      app_id: this.appService.app.id,
    });
  }
}
