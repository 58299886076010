/* RESPONSIBLE TEAM: team-messenger */
import ApplicationAdapter from 'embercom/adapters/application';
import ajax from 'embercom/lib/ajax';
import getAppId from 'embercom/lib/get-app-id';

export default class PushCredentialAdapter extends ApplicationAdapter {
  createRecord(store, type, snapshot) {
    let formData = new FormData();
    let record = snapshot.record;
    formData = this.updateAttributes(record, formData);

    return ajax({
      url: `/ember/push_credentials`,
      method: 'POST',
      data: formData,
      contentType: false,
      processData: false,
    });
  }

  updateRecord(store, type, snapshot) {
    let formData = new FormData();
    let record = snapshot.record;
    formData = this.updateAttributes(record, formData);

    return ajax({
      url: `/ember/push_credentials/${record.id}`,
      method: 'PUT',
      data: formData,
      contentType: false,
      processData: false,
    });
  }

  updateAttributes(record, formData) {
    formData.append('app_id', getAppId(this));
    formData.append('sdk_app_id', record.sdk_app_id);
    formData.append(`mobile_app_name`, record.mobileAppName);
    formData.append(`bundle_id`, record.bundleId);
    if (record.teamId) {
      formData.append(`team_id`, record.teamId);
    }
    if (record.keyId) {
      formData.append(`key_id`, record.keyId);
    }
    if (record.tokenFile) {
      formData.append(`token_file`, record.tokenFile);
      formData.append(`token_file_name`, record.tokenFileName);
    }
    if (record.fcmServerKey) {
      formData.append(`fcm_server_key`, record.fcmServerKey);
    }

    return formData;
  }
}
