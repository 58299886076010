/* RESPONSIBLE TEAM: team-frontend-tech */
import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { type TransitionContext } from 'ember-animated';

// eslint-disable-next-line require-yield
const BasicListAnimation = function* ({
  keptSprites,
  insertedSprites,
  removedSprites,
}: TransitionContext) {
  keptSprites.forEach((sprite) => move(sprite));
  insertedSprites.forEach((sprite) => fadeIn(sprite));
  removedSprites.forEach((sprite) => fadeOut(sprite));
};

export default BasicListAnimation;
