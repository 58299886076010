/* RESPONSIBLE TEAM: team-data-interop */
import ApplicationAdapter from 'embercom/adapters/application';
import { get, post } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';

export default ApplicationAdapter.extend({
  appService: service(),

  queryRecord() {
    return get('/app_packages/jira/settings', {
      app_id: this.appService.app.id,
    });
  },

  updateRecord() {
    return this.updateAttribute(...arguments);
  },

  updateAttribute(store, type, snapshot) {
    let { is_enabled, intercom_jira_ticket_type_mappings, sync_comments, automation_type } =
      this.serialize(snapshot);
    return post(`/app_packages/jira/settings`, {
      app_id: this.appService.app.id,
      is_enabled,
      intercom_jira_ticket_type_mappings,
      sync_comments,
      automation_type,
    });
  },
});
