/* RESPONSIBLE TEAM: team-messenger */
import ApplicationAdapter from './application';
import ajax from 'embercom/lib/ajax';

export default ApplicationAdapter.extend({
  updateRecord(store, type, snapshot) {
    /*
     * SdkApp defines its own `updateRecord` so we can use FormData to post
     * the PEM file contents and still maintain the model's dirty state.
     * See https://github.com/intercom/intercom/issues/28571 for more.
     */
    let url = this.urlForUpdateRecord(snapshot.id, type.modelName, snapshot);
    let formData = new FormData();
    let record = snapshot.record;
    formData.append('app_id', record.app_id);
    formData.append('name', record.name);
    formData.append('identity_verified', record.identity_verified);
    formData.append('has_certificate', record.has_certificate);
    if (record.certificate) {
      formData.append('certificate', record.certificate);
      formData.append('certificate_filename', record.get('certificate.name'));
    }
    if (record.isAndroid) {
      formData.append('gcm_key', record.gcm_key);
    }
    if (record.hasApnsToken) {
      if (record.apnsTokenFile) {
        formData.append('apns_token_file', record.apnsTokenFile);
        formData.append('apns_token_file_name', record.apnsTokenFileName);
      }
      formData.append('apns_key_id', record.apnsKeyId);
      formData.append('apns_team_id', record.apnsTeamId);
      formData.append('ios_bundle_id', record.iosBundleId);
      formData.append('has_apns_token', true);
    } else {
      formData.append('has_apns_token', false);
    }
    return ajax({
      url,
      method: 'PUT',
      data: formData,
      contentType: false,
      processData: false,
    });
  },
});
