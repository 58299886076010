/* RESPONSIBLE TEAM: team-product-guidance */
import ApplicationAdapter from 'embercom/adapters/application';

export default ApplicationAdapter.extend({
  pathForType() {
    return 'onboarding/home/guide';
  },

  urlForFindRecord() {
    return this.buildURL('onboarding/home/guide');
  },
});
