/* RESPONSIBLE TEAM: team-growth-opportunities */
import RESTAdapter from '@ember-data/adapter/rest';

export default class SignupAdaptor extends RESTAdapter {
  namespace = 'ember';

  state() {
    let url = `${this.buildURL('signup')}/state`;
    return this.ajax(url, 'GET', {});
  }
}
