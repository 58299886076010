/* RESPONSIBLE TEAM: team-reporting */
import ApplicationAdapter from 'embercom/adapters/application';
import { inject as service } from '@ember/service';

export default class ReportAdapter extends ApplicationAdapter {
  @service appService;

  urlForCreateRecord(modelName, snapShot) {
    if (this.appService.app.canSeeR2Beta) {
      return `${this.buildURL(modelName)}/update_report_and_charts`;
    } else {
      return super.urlForCreateRecord(...arguments);
    }
  }

  urlForUpdateRecord(modelName, snapShot) {
    if (this.appService.app.canSeeR2Beta) {
      return `${this.buildURL(modelName)}/update_report_and_charts`;
    } else {
      return super.urlForUpdateRecord(...arguments);
    }
  }
  pathForType() {
    return 'reporting/custom_reports';
  }
}
